import { useCallback } from 'react';

import { useCommerce } from '@commerce';
import type { Cart, LineItem, RemoveItemHook } from '@commerce/types/cart';
import { ValidationError } from '@commerce/utils/errors';
import type { HookFetcherContext, MutationHook, MutationHookContext } from '@commerce/utils/types';
import { useMutationHook } from '@commerce/utils/use-hook';
import { addQueryString } from '@framework/lib/add-query-string';
import { trackEvent, trackGA4RemoveItemEntirelyFromCartEvent } from '@lib/gtag';

import useCart from './use-cart';

export type RemoveItemFn<T = any> = T extends LineItem
  ? (input?: RemoveItemActionInput<T>) => Promise<Cart | null | undefined>
  : (input: RemoveItemActionInput<T>) => Promise<Cart | null>;

export type RemoveItemActionInput<T = any> = T extends LineItem
  ? Partial<RemoveItemHook['actionInput']>
  : RemoveItemHook['actionInput'];

export const handler = {
  fetchOptions: {
    url: '/api/cart',
    method: 'DELETE',
  },
  async fetcher({ input: { itemId, locale }, options, fetch }: HookFetcherContext<RemoveItemHook>) {
    const url = addQueryString(options.url!, { locale });
    try {
      const data = await fetch({ ...options, url: url.pathname + url.search, body: { itemId } });
      return data;
    } catch (err: any) {
      if (err?.status === 404) {
        // returns empty data
        // cart mutate will refetch/reload the cart
        return null;
      }

      throw err;
    }
  },
  useHook: ({ fetch }: MutationHookContext<RemoveItemHook>) =>
    function useCustomHook<T extends LineItem | undefined = undefined>(ctx: { item?: T } = {}) {
      const { item } = ctx;
      const { mutate } = useCart();
      const { locale } = useCommerce();

      const removeItem: RemoveItemFn<LineItem> = async (input) => {
        const itemId = input?.id ?? item?.id;

        if (!itemId) {
          throw new ValidationError({
            message: 'Invalid input used for this operation',
          });
        }

        const data = await fetch({ input: { itemId, locale } });

        trackGA4RemoveItemEntirelyFromCartEvent(input);
        trackEvent({
          action: `Changed Item Quantity Remove ${input?.quantity}`,
          category: 'Cart',
          label: item?.variant?.sku,
        });

        await mutate(data, false);
        return data;
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      return useCallback(removeItem as RemoveItemFn<T>, [fetch, mutate]);
    },
};

export type UseRemoveItem<H extends MutationHook<RemoveItemHook<any>> = MutationHook<RemoveItemHook>> = ReturnType<
  H['useHook']
>;

const useRemoveItem: UseRemoveItem = (input) => {
  return useMutationHook({ ...handler })(input);
};

export default useRemoveItem as UseRemoveItem<typeof handler>;
