import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ChangeEvent, FC, JSXElementConstructor, useCallback, useEffect, useMemo, useState } from 'react';
import useDeepEffect from 'use-deep-compare-effect';

import type { LineItem } from '@commerce/types/cart';
import type { Product } from '@commerce/types/product';
import { ProductAttributeField, SubscriptionTerm, SubscriptionType } from '@components/product/enums';
import { getProductImages, getProductInfo, getProductsBySKUs, getSalePrice } from '@components/product/helpers';
import Pricing from '@components/product/Pricing';
import { Price } from '@components/product/types';
import { LoadingDots, Text } from '@components/ui';
import useRemoveItem from '@framework/cart/use-remove-item';
import useUpdateItem from '@framework/cart/use-update-item';
import useCustomer from '@framework/customer/use-customer';
import usePrice from '@framework/product/use-price';
import { BigCommerceProductImage } from '@lib/bigcommerce-product-image';
import getErrorCode from '@lib/get-error-code';
import useToastify from '@lib/hooks/useToastify';
import { logger } from '@lib/logger';
import { captureException } from '@lib/sentry';

import { getPremiumDataFromSKU, isPremiumSKU } from '../helpers';
import { ItemField } from '../types/ItemField';

import style from './CartItem.module.scss';

interface Props {
  className?: string;
  item: LineItem;
  product?: Product | null;
  currencyCode: string;
  showAction?: boolean;
  smallContainer?: boolean;
  hasFreeTrial?: boolean;
  asElement?: React.ComponentType<any> | string;
  giftBoxSku?: string;
  isOutOfStock?: boolean;
  insufficientStock?: { name: string; available: number };
}

const DURING_TOAST_MESSAGE_MILLISECONDS = 7000;

const getCartItemPricing = (lineItem: LineItem, { prices }: Product) => {
  const productSalePrice = getSalePrice(prices);
  return {
    price:
      lineItem.variant.price && typeof lineItem.variant.price === 'number' ? lineItem.variant.price : productSalePrice,
    originalPrice: prices.retailPrice.value,
    currencyCode: prices.price.currencyCode,
  };
};

const CartItem: FC<Props> = ({
  item,
  product: productProp,
  currencyCode,
  className,
  asElement = 'div',
  showAction = true,
  smallContainer = false,
  hasFreeTrial: eligibleForFreeTrial,
  giftBoxSku,
  isOutOfStock = false,
  insufficientStock,
}) => {
  const { t } = useTranslation(['common', 'cart', 'product', 'checkout']);
  const { locale } = useRouter();
  const toast = useToastify();
  const { isSubscriber, hasFreeTrial: customerHasFreeTrial } = useCustomer();
  const updateItem = useUpdateItem({ item });
  const removeItem = useRemoveItem();
  const [quantityInputValue, setQuantityInputValue] = useState<number | ''>(item.quantity);
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(!productProp);
  const [removing, setRemoving] = useState(false);
  const [product, setProduct] = useState<Product | null>(productProp || null);
  const [fields, setFields] = useState<ItemField[]>([]);
  const [pricing, setPricing] = useState<Price | null>(null);
  const isGiftBox = item.variant.sku === giftBoxSku;
  const premiumData = getPremiumDataFromSKU(product?.sku || '', locale);
  const isPremiumPlan = !!premiumData;
  const { price: originalPrice } = usePrice({
    amount: product?.prices.retailPrice.value || 0,
    currencyCode,
  });

  // set free trial status passed from checkout (if available)
  // fallback to free trial status from customer data
  const hasFreeTrial = useMemo(
    () => (typeof eligibleForFreeTrial === 'boolean' ? eligibleForFreeTrial : customerHasFreeTrial),
    [eligibleForFreeTrial, customerHasFreeTrial]
  );

  const isUpsell = isPremiumPlan && premiumData.type === SubscriptionType.UPSELL;

  const itemOosOrInsufficient = isOutOfStock || !!insufficientStock;

  const isMutable = !!item.mutable;

  const isFree = useMemo(() => {
    // workaround for free item promotion, check from lineItem variant pricing (as discount value will be 0)
    const listItemPricing =
      item.variant && (!Number.isNaN(item.variant.price) ? item.variant.price : item.variant.listPrice);
    const productPricing = pricing && (!Number.isNaN(pricing.price) ? pricing.price : pricing.originalPrice);
    // compare discounts with extended price (quantity * item price)
    const extendedProductPrice = productPricing && productPricing * (item.quantity || 1);
    const extendedListItemPrice = listItemPricing * (item.quantity || 1);
    const hasDiscounts = item.discounts?.length > 0;
    const cartItemDiscounts = hasDiscounts ? item.discounts.reduce((acc, { value }) => acc + value, 0) : 0;
    return hasDiscounts
      ? cartItemDiscounts === extendedProductPrice || cartItemDiscounts === extendedListItemPrice
      : false;
  }, [item.discounts, item.variant, item.quantity, pricing]);

  const updateQuantity = useCallback(
    async (val: number): Promise<boolean> => {
      setIsUpdating(true);
      try {
        const result = await updateItem({ quantity: val });
        const newQuantity = result?.lineItems.find((lineItem) => lineItem.id === item.id)?.quantity;

        if (newQuantity) {
          // edge case: you change quantity on a Free line item
          setQuantityInputValue(newQuantity);
        }
        return true;
      } catch (err) {
        const errorCode = getErrorCode(err);
        toast.error(t(`cart:error.add.${errorCode}`), { autoClose: DURING_TOAST_MESSAGE_MILLISECONDS });
        // for when user update quantity via textbox input, restore to the original item.quantity
        setQuantityInputValue(item.quantity);
        return false;
      } finally {
        setIsUpdating(false);
      }
    },
    [t, toast, updateItem, item.quantity, item.id]
  );

  const handleQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    const val = !e.target.value ? '' : Number(e.target.value);

    if (!val || (Number.isInteger(val) && val >= 0)) {
      setQuantityInputValue(val);
    }
  };

  const handleBlur = () => {
    const val = Number(quantityInputValue);

    if (val !== item.quantity) {
      updateQuantity(val);
    }
  };

  const increaseQuantity = useCallback(
    async (n: number) => {
      const val = item.quantity + n;

      if (val >= 0) {
        await updateQuantity(val);
      }
    },
    [item.quantity, updateQuantity]
  );

  const handleRemove = async () => {
    setRemoving(true);

    try {
      // If this action succeeds then there's no need to do `setRemoving(true)`
      // because the component will be removed from the view
      await removeItem(item);
    } catch {
      setRemoving(false);
    }
  };

  useDeepEffect(() => {
    if (product && item) {
      const { packSize, sku, description } = getProductInfo(product);
      setPricing(getCartItemPricing(item, product));

      const itemFields: ItemField[] = [];

      if (sku && !isPremiumSKU(sku, locale)) {
        if (isGiftBox && description) {
          itemFields.push({ label: description, value: '' });
        }
        if (!isGiftBox) {
          itemFields.push({ label: t('cart:label.packSize'), value: t('cart:value.packSize', { packSize }) });
        }
      }

      if (smallContainer || !item.mutable) {
        itemFields.push({
          label: t('cart:label.quantity'),
          value: `${item.quantity}`,
        });
      }
      setFields(itemFields);
    }
  }, [product, item, smallContainer, isGiftBox]);

  useEffect(() => {
    async function getProduct(sku: string) {
      try {
        // gql getBySKU for subscription product (not assigned to any category) will return null in BigC for some reason
        // hence using /products?skus= to get with REST API
        setLoading(true);
        const products = await getProductsBySKUs([sku], locale);
        if (products?.length > 0) {
          setProduct(products[0]);
        }
      } catch (error) {
        captureException(error);
        logger.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (item.variant?.sku && !product) {
      getProduct(item.variant.sku);
    }
  }, [product, item.variant?.sku, locale]);

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantityInputValue)) {
      setQuantityInputValue(item.quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity]);

  const renderAction = (actionClass?: string) => (
    <section className={actionClass} data-testid="action-container">
      <button type="button" onClick={handleRemove} data-cy="cart-item-remove" aria-label={t('cart:label.removeItem')}>
        {!removing ? (
          <Text variant="base" weight="semibold" color="var(--cta)" asElement="p">
            {t('cart:action.remove')}
          </Text>
        ) : (
          <LoadingDots className="text-hover-cta" />
        )}
      </button>
      {/* don't render quantity update for premium and outOfStock item */}
      {!isPremiumPlan && !isOutOfStock && (
        <div className="text-center">
          <div className={style.quantityWrapper}>
            <button
              type="button"
              data-cy="cart-item-decrease-quantity"
              aria-label={t('cart:aria.qtyDown')}
              disabled={isUpdating}
              className={cn(style.quantityButton, 'border-r border-outline-primary')}
              // for insufficient stock, only allow reducing quantity to available stock
              onClick={() =>
                increaseQuantity((insufficientStock && insufficientStock.available - Number(item.quantity)) || -1)
              }
            >
              <FontAwesomeIcon className="text-secondary" icon={faMinus} title={t('cart:aria.qtyDown')} />
            </button>
            <label htmlFor="quantity" className="sr-only">
              {`${t('cart:label.item')} ${t('cart:label.quantity')}`}
            </label>
            <input
              data-cy="cart-item-quantity"
              type="number"
              name="quantity"
              disabled={isUpdating}
              max={99}
              min={0}
              className={style.quantity}
              value={quantityInputValue}
              onChange={handleQuantity}
              onBlur={handleBlur}
            />
            {/* don't show increase button if stock is insufficient */}
            {!insufficientStock && (
              <button
                type="button"
                data-cy="cart-item-increase-quantity"
                aria-label={t('cart:aria.qtyUp')}
                disabled={isUpdating}
                className={cn(style.quantityButton, 'border-l border-outline-primary')}
                onClick={() => increaseQuantity(1)}
              >
                <FontAwesomeIcon className="text-secondary" icon={faPlus} title={t('cart:aria.qtyUp')} />
              </button>
            )}
          </div>
        </div>
      )}
    </section>
  );

  if (loading) {
    return (
      <div className={cn(style.root, 'flex w-full items-center justify-center')}>
        <LoadingDots />
      </div>
    );
  }

  const Component: JSXElementConstructor<any> | React.ReactElement<any> | React.ComponentType<any> | string = asElement;

  const img = product?.images && product.images.length > 0 ? getProductImages(product.images).defaultImage : null;

  const renderPremiumPricing = (wrapperClass?: string) =>
    isPremiumPlan &&
    originalPrice && (
      <div className={wrapperClass}>
        <Text asElement="span" color="var(--text-primary)" variant="base-bold" className="mr-1">
          {t(`checkout:label.${premiumData.term === SubscriptionTerm.MONTH ? 'pricePerMonth' : 'pricePerYear'}`, {
            price: originalPrice,
          })}
        </Text>
        {(hasFreeTrial || isUpsell) && (
          <Text variant="base" asElement="span">
            {t('cart:label.afterTrial')}
          </Text>
        )}
      </div>
    );

  const subscriptionCartText = product?.details?.markdownAttributes[ProductAttributeField.SUBSCRIPTION_CART_TEXT] || '';

  return product ? (
    <Component
      className={cn(style.root, 'flex flex-row gap-x-2', className, {
        'opacity-75 pointer-events-none': removing,
      })}
      data-cy="cart-item"
      data-slug={product.slug}
    >
      <>
        <Link
          href={`/product/${product.slug}`}
          className={cn(
            { 'pointer-events-none': isPremiumPlan || itemOosOrInsufficient },
            { [style.disabled]: itemOosOrInsufficient }
          )}
        >
          {img && (
            <BigCommerceProductImage
              size="thumbnail"
              image={img}
              options={{ className: `${style.productImg} m-auto object-contain` }}
            />
          )}
        </Link>
        <div className={style.content}>
          <section className={cn({ [style.disabled]: itemOosOrInsufficient })}>
            <div className="flex justify-between items-start">
              <Text variant="base-bold" className={style.name} asElement="h3">
                {product.name}
              </Text>
              {!smallContainer &&
                !isPremiumPlan &&
                pricing &&
                (isFree ? (
                  <Text variant="base" className="hidden md:block" asElement="span" data-cy="free-pricing">
                    {t('checkout:common.free')}
                  </Text>
                ) : (
                  <Pricing {...pricing} currencyCode={currencyCode} textVariant="base" className="hidden md:flex" />
                ))}
              {!smallContainer && renderPremiumPricing('hidden md:block')}
            </div>
            {!isPremiumPlan &&
              pricing &&
              (isFree ? (
                <Text variant="base" className={cn({ 'md:hidden': !smallContainer })} asElement="span">
                  {t('checkout:common.free')}
                </Text>
              ) : (
                <Pricing
                  {...pricing}
                  currencyCode={currencyCode}
                  textVariant="base"
                  className={cn({ 'md:hidden': !smallContainer })}
                />
              ))}
            {isPremiumPlan && !isSubscriber && hasFreeTrial && subscriptionCartText ? (
              <div className="mb-2">
                <Text variant="base">{subscriptionCartText.split(/\r?\n/)[0]}</Text>
                {!!subscriptionCartText.split(/\r?\n/)[1] && (
                  <Text variant="base">{`${subscriptionCartText.split(/\r?\n/)[1]}`}</Text>
                )}
              </div>
            ) : null}
            {(!isPremiumPlan || (isPremiumPlan && !hasFreeTrial)) && fields && (
              <ul className="mt-1 mb-xs">
                {fields.map(({ label, value }) =>
                  value ? (
                    <li key={label}>
                      <Text variant="text-4" asElement="span">
                        {label}:
                      </Text>
                      <Text variant="text-4" asElement="span" weight="semibold" className="ml-1">
                        {value}
                      </Text>
                    </li>
                  ) : (
                    <Text key={label} variant="text-4" asElement="span">
                      {label}
                    </Text>
                  )
                )}
              </ul>
            )}
            {renderPremiumPricing(cn({ 'md:hidden': !smallContainer }, 'block mt-2'))}
          </section>
          {showAction && isMutable && renderAction(style.action)}
        </div>
      </>
    </Component>
  ) : (
    <Component className={cn(style.root, 'flex w-100 h-100 items-center justify-center')}>
      <Text variant="text-3" color="var(--error)">
        {t('cart:error.failToLoad')}
      </Text>
    </Component>
  );
};

export default CartItem;
